import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import CachedIcon from '@material-ui/icons/Cached';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(),
    width: '100%',
  },
  button: {
    marginTop: '16px',
  },
});

class LeaderboardFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
    };

    this.categories = [
      { name: 'Feminino', key: 'women' },
      { name: 'Masculino', key: 'men' },
    ];
  }

  refreshLeaderboard = () => {
    const { category } = this.state;
    if (category !== '') {
      this.props.onChange(category);
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { classes } = this.props;
    const { category } = this.state;

    // TODO category filter
    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-read-only-input"
            label="Campeonato"
            defaultValue="Malta Weekend League 2023"
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-helper">Categoria</InputLabel>
          <Select
            value={category}
            onChange={this.handleChange}
            input={<Input name="category" id="category-helper" />}
          >
            <MenuItem value="">
              <em />
            </MenuItem>

            {this.categories.map(category => (
              <MenuItem key={category.key} value={category.key}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endicon={<CachedIcon />}
          onClick={this.refreshLeaderboard}
        >
          Atualizar
        </Button>
      </form>
    );
  }
}

LeaderboardFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
)(LeaderboardFilter);
