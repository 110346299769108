import React from 'react';
import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import PersonIcon from '@material-ui/icons/Person';
import ScoreIcon from '@material-ui/icons/Score';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <EqualizerIcon />
      </ListItemIcon>
      <ListItemText primary="Leaderboards" />
    </ListItem>
  </div>
);

export const managementListItems = (
  <div>
    <ListSubheader inset>Gerenciamento</ListSubheader>
    <ListItem button component={Link} to="/competicoes/gerenciar">
      <ListItemIcon>
        <HourglassFullIcon />
      </ListItemIcon>
      <ListItemText primary="Competições" />
    </ListItem>

    <ListItem button component={Link} to="/competidores/gerenciar">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Competidores" />
    </ListItem>

    <ListItem button component={Link} to="/resultados/gerenciar">
      <ListItemIcon>
        <ScoreIcon />
      </ListItemIcon>
      <ListItemText primary="Resultados" />
    </ListItem>

    <ListItem button component={Link} to="/ranking/gerenciar">
      <ListItemIcon>
        <CheckCircleOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="Ranking" />
    </ListItem>
  </div>
);
