import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Dashboard from '../dashboard';
import LeaderboardFilter from '../../components/leaderboard-filter';
import LeaderboardTable from '../../components/leaderboard-table';

const styles = theme => ({});

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
    };
  };

  handleCompetition = (category) => {
    this.setState({ category });
  };

  render() {
    const { category } = this.state;

    return (
      <Dashboard enableLogin={false}>
        <div className="Leaderboard">
          <LeaderboardFilter onChange={this.handleCompetition} />
          {category && (
            <LeaderboardTable category={category} />
          )}
        </div>
      </Dashboard>
    );
  }
}

Leaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter,
)(Leaderboard);
