import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';

import Topbar from '../../components/topbar';
import createStyle from './style';
import { mainListItems, managementListItems } from './listItems';
import { AUTH_TOKEN } from '../../constants';

class Dashboard extends Component {
  state = {
    open: false,
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, children, enableLogin } = this.props;
    const drawerClasses = clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose);
    const authToken = localStorage.getItem(AUTH_TOKEN);

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Topbar isOpen={this.state.open} toggleOpen={this.toggleOpen} enableLogin={enableLogin} />

        {authToken && (
          <Drawer variant="permanent" classes={{ paper: drawerClasses }} open={this.state.open}>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.toggleOpen}>
                <ChevronLeftIcon />
              </IconButton>
            </div>

            <Divider />
            <List>{mainListItems}</List>
            {authToken && (
              <div>
                <Divider />
                <List>{managementListItems}</List>
              </div>
            )}
          </Drawer>
        )}

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Typography variant="h4" gutterBottom component="h2">
            {children}
          </Typography>
        </main>
      </div>
    );
  }
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  enableLogin: PropTypes.bool,
};

export default withStyles(createStyle)(Dashboard);
