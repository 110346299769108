import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { fullLeaderboard } from '../../utils';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: 100,
    overflowX: 'auto',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
  },
});

class LeaderboardTable extends Component {
  render() {
    const { classes, category } = this.props;
    const leaderboard = fullLeaderboard[category];

    return (
      <div className="LeaderboardTable">
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>#</CustomTableCell>
                <CustomTableCell align="right">Atleta</CustomTableCell>

                <CustomTableCell>Pontos</CustomTableCell>

                <CustomTableCell align="center">
                  Snatch
                </CustomTableCell>

                <CustomTableCell align="center">
                  Clean & Jerk
                </CustomTableCell>

                <CustomTableCell align="center">
                  Wod 3
                </CustomTableCell>

                <CustomTableCell align="center">
                  Edvaldo Valério
                </CustomTableCell>

                <CustomTableCell align="center">
                  WET Triple 3
                </CustomTableCell>

                <CustomTableCell align="center">
                  Wod 6
                </CustomTableCell>

                <CustomTableCell align="center">
                  Strongman
                </CustomTableCell>

                <CustomTableCell align="center">
                  Gymnastic Skills
                </CustomTableCell>

                <CustomTableCell align="center">
                  Andrade Karen
                </CustomTableCell>

                <CustomTableCell align="center">
                  Gato e Rato
                </CustomTableCell>

                <CustomTableCell align="center">
                  5km / 10km
                </CustomTableCell>

                <CustomTableCell align="center">
                  Pontuação Descartada (-)
                </CustomTableCell>

              </TableRow>
            </TableHead>

            <TableBody>
              {leaderboard.map((competitor, index) => (
                <TableRow className={classes.row} key={`competitor-${index}`}>
                  <CustomTableCell component="th" scope="row">
                    {competitor.index
                      ? competitor.index
                      : index + 1
                    }
                  </CustomTableCell>

                  <CustomTableCell align="right">
                    {competitor.athletes}
                  </CustomTableCell>

                  <CustomTableCell component="th" scope="row">
                    {competitor.ranking === 0
                      ? '--'
                      : competitor.ranking
                    }
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-1'] !== '' && (
                      `${competitor['wod-1-display'] || competitor['wod-1']} (${competitor['wod-1-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-2'] !== '' && (
                      `${competitor['wod-2-display'] || competitor['wod-2']} (${competitor['wod-2-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-3'] !== '' && (
                      `${competitor['wod-3-display'] || competitor['wod-3']} (${competitor['wod-3-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-4'] !== '' && (
                      `${competitor['wod-4-display'] || competitor['wod-4']} (${competitor['wod-4-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-5'] !== '' && (
                      `${competitor['wod-5-display'] || competitor['wod-5']} (${competitor['wod-5-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-6'] !== '' && (
                      `${competitor['wod-6-display'] || competitor['wod-6']} (${competitor['wod-6-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-7'] !== '' && (
                      `${competitor['wod-7-display'] || competitor['wod-7']} (${competitor['wod-7-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-8'] !== '' && (
                      `${competitor['wod-8-display'] || competitor['wod-8']} (${competitor['wod-8-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-9'] !== '' && (
                      `${competitor['wod-9-display'] || competitor['wod-9']} (${competitor['wod-9-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-10'] !== '' && (
                      `${competitor['wod-10-display'] || competitor['wod-10']} (${competitor['wod-10-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-11'] !== '' && (
                      `${competitor['wod-11-display'] || competitor['wod-11']} (${competitor['wod-11-score']})`
                    )}
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    {competitor['wod-discard'] !== '' && (
                      `${competitor['wod-discard']}`
                    )}
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

LeaderboardTable.propTypes = {
  classes: PropTypes.object.isRequired,
  category: PropTypes.string
};

export default compose(
  withStyles(styles),
)(LeaderboardTable);
