import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import createStyle from './style';
import cover from '../../assets/malta-topbar.png';

class Topbar extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="Topbar">
        <CssBaseline />

        <div className={classes.maltaTopbar}>
          <img width="100%" height="auto" src={cover} alt="Malta Weekend League 2023" className={classes.maltaCover} />
        </div>
      </div>
    );
  }
}

Topbar.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  enableLogin: PropTypes.bool,
};

export default compose (
  withStyles(createStyle),
  withRouter,
)(Topbar);
