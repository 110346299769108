export const fullLeaderboard = {
  men:
   [ { athletes: 'Kleber Rocha',
       ranking: '740',
       index: '1',
       'wod-1': '70',
       'wod-1-score': '79,545 kgs',
       'wod-1-display': '7º',
       'wod-2': '70',
       'wod-2-score': '102,27 kgs',
       'wod-2-display': '7º',
       'wod-3': '90',
       'wod-3-score': '08:41',
       'wod-3-display': '3º',
       'wod-4': '90',
       'wod-4-score': '33.86s',
       'wod-4-display': '3º',
       'wod-5': '85',
       'wod-5-score': '17:42',
       'wod-5-display': '4º',
       'wod-6': '100',
       'wod-6-score': '13:06',
       'wod-6-display': '1º',
       'wod-7': '95',
       'wod-7-score': '02:01',
       'wod-7-display': '2º',
       'wod-8': '100',
       'wod-8-score': '09:11',
       'wod-8-display': '1º',
       'wod-9': '90',
       'wod-9-score': '10:52',
       'wod-9-display': '3º',
       'wod-10': '90',
       'wod-10-score': '',
       'wod-10-display': '3º',
       'wod-11': '85',
       'wod-11-score': '01:00:09',
       'wod-11-display': '4º',
       'wod-discard': '225' },
     { athletes: 'Cleber Souza',
       ranking: '695',
       index: '2',
       'wod-1': '75',
       'wod-1-score': '81,454 kgs',
       'wod-1-display': '6º',
       'wod-2': '75',
       'wod-2-score': '104,27 kgs',
       'wod-2-display': '6º',
       'wod-3': '70',
       'wod-3-score': '10:45',
       'wod-3-display': '7º',
       'wod-4': '30',
       'wod-4-score': 'WO',
       'wod-4-display': '10º',
       'wod-5': '25',
       'wod-5-score': 'WO',
       'wod-5-display': '10º',
       'wod-6': '70',
       'wod-6-score': 'CAP + 10',
       'wod-6-display': '7º',
       'wod-7': '100',
       'wod-7-score': '01:59',
       'wod-7-display': '1º',
       'wod-8': '90',
       'wod-8-score': '10:00',
       'wod-8-display': '3º',
       'wod-9': '95',
       'wod-9-score': '10:47',
       'wod-9-display': '2º',
       'wod-10': '100',
       'wod-10-score': '',
       'wod-10-display': '1º',
       'wod-11': '95',
       'wod-11-score': '00:52:02',
       'wod-11-display': '2º',
       'wod-discard': '130' },
     { athletes: 'Mateus Falcão',
       ranking: '695',
       index: '2',
       'wod-1': '90',
       'wod-1-score': '93,181 kgs',
       'wod-1-display': '3º',
       'wod-2': '95',
       'wod-2-score': '124,45 kgs',
       'wod-2-display': '2º',
       'wod-3': '100',
       'wod-3-score': '08:16',
       'wod-3-display': '1º',
       'wod-4': '30',
       'wod-4-score': 'WO',
       'wod-4-display': '10º',
       'wod-5': '30',
       'wod-5-score': 'WO',
       'wod-5-display': '10º',
       'wod-6': '95',
       'wod-6-score': '13:08',
       'wod-6-display': '2º',
       'wod-7': '90',
       'wod-7-score': '02:16',
       'wod-7-display': '3º',
       'wod-8': '80',
       'wod-8-score': '11:18',
       'wod-8-display': '5º',
       'wod-9': '70',
       'wod-9-score': '14:31',
       'wod-9-display': '7º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '75',
       'wod-11-score': '01:05:38',
       'wod-11-display': '6º',
       'wod-discard': '93' },
     { athletes: 'Cristiano Santos',
       ranking: '690',
       index: '4',
       'wod-1': '65',
       'wod-1-score': '78,454 kgs',
       'wod-1-display': '8º',
       'wod-2': '80',
       'wod-2-score': '113,36 kgs',
       'wod-2-display': '5º',
       'wod-3': '80',
       'wod-3-score': '10:29',
       'wod-3-display': '5º',
       'wod-4': '95',
       'wod-4-score': '32.31s',
       'wod-4-display': '2º',
       'wod-5': '95',
       'wod-5-score': '15:00',
       'wod-5-display': '2º',
       'wod-6': '30',
       'wod-6-score': 'WO',
       'wod-6-display': '10º',
       'wod-7': '80',
       'wod-7-score': '02:29',
       'wod-7-display': '5º',
       'wod-8': '95',
       'wod-8-score': '09:51',
       'wod-8-display': '2º',
       'wod-9': '33',
       'wod-9-score': 'WO',
       'wod-9-display': '9º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '100',
       'wod-11-score': '00:48:26',
       'wod-11-display': '1º',
       'wod-discard': '96' },
     { athletes: 'Filipe Dultra',
       ranking: '685',
       index: '5',
       'wod-1': '90',
       'wod-1-score': '93,181 kgs',
       'wod-1-display': '3º',
       'wod-2': '90',
       'wod-2-score': '120,45 kgs',
       'wod-2-display': '3º',
       'wod-3': '95',
       'wod-3-score': '08:22',
       'wod-3-display': '2º',
       'wod-4': '30',
       'wod-4-score': 'WO',
       'wod-4-display': '10º',
       'wod-5': '30',
       'wod-5-score': 'WO',
       'wod-5-display': '10º',
       'wod-6': '90',
       'wod-6-score': '13:38',
       'wod-6-display': '3º',
       'wod-7': '70',
       'wod-7-score': '02:32',
       'wod-7-display': '7º',
       'wod-8': '85',
       'wod-8-score': '10:51',
       'wod-8-display': '4º',
       'wod-9': '100',
       'wod-9-score': '10:32',
       'wod-9-display': '1º',
       'wod-10': '65',
       'wod-10-score': '',
       'wod-10-display': '8º',
       'wod-11': '35',
       'wod-11-score': 'WO',
       'wod-11-display': '8º',
       'wod-discard': '95' },
     { athletes: 'Gabriel Gene',
       ranking: '665',
       index: '6',
       'wod-1': '100',
       'wod-1-score': '104,270 kgs',
       'wod-1-display': '1º',
       'wod-2': '85',
       'wod-2-score': '117,36 kgs',
       'wod-2-display': '4º',
       'wod-3': '75',
       'wod-3-score': '10:43',
       'wod-3-display': '6º',
       'wod-4': '85',
       'wod-4-score': '37.49s',
       'wod-4-display': '4º',
       'wod-5': '80',
       'wod-5-score': 'CAP + 4',
       'wod-5-display': '5º',
       'wod-6': '65',
       'wod-6-score': 'CAP + 19',
       'wod-6-display': '8º',
       'wod-7': '85',
       'wod-7-score': '02:27',
       'wod-7-display': '4º',
       'wod-8': '65',
       'wod-8-score': 'CAP + 26',
       'wod-8-display': '8º',
       'wod-9': '75',
       'wod-9-score': '14:22',
       'wod-9-display': '6º',
       'wod-10': '80',
       'wod-10-score': '',
       'wod-10-display': '5º',
       'wod-11': '70',
       'wod-11-score': '01:07:13',
       'wod-11-display': '7º',
       'wod-discard': '200' },
     { athletes: 'Rafael Falcão',
       ranking: '645',
       index: '7',
       'wod-1': '80',
       'wod-1-score': '84,09 kgs',
       'wod-1-display': '5º',
       'wod-2': '70',
       'wod-2-score': '102,27 kgs',
       'wod-2-display': '7º',
       'wod-3': '65',
       'wod-3-score': '11:07',
       'wod-3-display': '8º',
       'wod-4': '100',
       'wod-4-score': '31.14s',
       'wod-4-display': '1º',
       'wod-5': '75',
       'wod-5-score': 'CAP + 192',
       'wod-5-display': '6º',
       'wod-6': '75',
       'wod-6-score': 'CAP + 8',
       'wod-6-display': '6º',
       'wod-7': '75',
       'wod-7-score': '02:32 (tiebreak)',
       'wod-7-display': '6º',
       'wod-8': '70',
       'wod-8-score': 'CAP + 22',
       'wod-8-display': '7º',
       'wod-9': '85',
       'wod-9-score': '',
       'wod-9-display': '4º',
       'wod-10': '75',
       'wod-10-score': '',
       'wod-10-display': '6º',
       'wod-11': '80',
       'wod-11-score': '01:04:59',
       'wod-11-display': '5º',
       'wod-discard': '205' },
     { athletes: 'Saulo Henri',
       ranking: '598',
       index: '8',
       'wod-1': '95',
       'wod-1-score': '97,181 kgs',
       'wod-1-display': '2º',
       'wod-2': '100',
       'wod-2-score': '126,45 kgs',
       'wod-2-display': '1º',
       'wod-3': '85',
       'wod-3-score': '10:13',
       'wod-3-display': '4º',
       'wod-4': '75',
       'wod-4-score': '38.37s',
       'wod-4-display': '6º',
       'wod-5': '90',
       'wod-5-score': '17:27',
       'wod-5-display': '3º',
       'wod-6': '85',
       'wod-6-score': '14:35',
       'wod-6-display': '4º',
       'wod-7': '30',
       'wod-7-score': 'WO',
       'wod-7-display': '10º',
       'wod-8': '30',
       'wod-8-score': 'WO',
       'wod-8-display': '10º',
       'wod-9': '33',
       'wod-9-score': 'WO',
       'wod-9-display': '9º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '35',
       'wod-11-score': 'WO',
       'wod-11-display': '8º',
       'wod-discard': '93' },
     { athletes: 'Fabio Noronha',
       ranking: '575',
       index: '9',
       'wod-1': '55',
       'wod-1-score': '65,363 kgs',
       'wod-1-display': '10º',
       'wod-2': '60',
       'wod-2-score': '90,09 kgs',
       'wod-2-display': '9º',
       'wod-3': '60',
       'wod-3-score': 'CAP + 2',
       'wod-3-display': '9º',
       'wod-4': '65',
       'wod-4-score': '40.84s',
       'wod-4-display': '8º',
       'wod-5': '65',
       'wod-5-score': 'CAP + 302',
       'wod-5-display': '8º',
       'wod-6': '80',
       'wod-6-score': 'CAP + 7',
       'wod-6-display': '5º',
       'wod-7': '60',
       'wod-7-score': '02:58',
       'wod-7-display': '9º',
       'wod-8': '75',
       'wod-8-score': '11:30',
       'wod-8-display': '6º',
       'wod-9': '80',
       'wod-9-score': '14:10',
       'wod-9-display': '5º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '90',
       'wod-11-score': '00:54:43',
       'wod-11-display': '3º',
       'wod-discard': '148' },
     { athletes: 'Alisson Bonfim',
       ranking: '525',
       index: '10',
       'wod-1': '60',
       'wod-1-score': '70,454 kgs',
       'wod-1-display': '9º',
       'wod-2': '55',
       'wod-2-score': '79,454 kgs',
       'wod-2-display': '10º',
       'wod-3': '55',
       'wod-3-score': 'CAP + 23',
       'wod-3-display': '10º',
       'wod-4': '60',
       'wod-4-score': '55.18s',
       'wod-4-display': '9º',
       'wod-5': '70',
       'wod-5-score': 'CAP + 240',
       'wod-5-display': '7º',
       'wod-6': '60',
       'wod-6-score': 'CAP + 39',
       'wod-6-display': '9º',
       'wod-7': '65',
       'wod-7-score': '02:50',
       'wod-7-display': '8º',
       'wod-8': '60',
       'wod-8-score': 'CAP + 27',
       'wod-8-display': '9º',
       'wod-9': '65',
       'wod-9-score': '16:00',
       'wod-9-display': '8º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '35',
       'wod-11-score': 'WO',
       'wod-11-display': '8º',
       'wod-discard': '93' },
     { athletes: 'Cacauzinho',
       ranking: '371',
       index: '11',
       'wod-1': '27',
       'wod-1-score': 'WO',
       'wod-1-display': '11º',
       'wod-2': '25',
       'wod-2-score': 'WO',
       'wod-2-display': '11º',
       'wod-3': '12',
       'wod-3-score': 'WO',
       'wod-3-display': '12º',
       'wod-4': '80',
       'wod-4-score': '37.58s',
       'wod-4-display': '5º',
       'wod-5': '100',
       'wod-5-score': '12:58',
       'wod-5-display': '1º',
       'wod-6': '30',
       'wod-6-score': 'WO',
       'wod-6-display': '10º',
       'wod-7': '30',
       'wod-7-score': 'WO',
       'wod-7-display': '10º',
       'wod-8': '30',
       'wod-8-score': 'WO',
       'wod-8-display': '10º',
       'wod-9': '33',
       'wod-9-score': 'WO',
       'wod-9-display': '9º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '35',
       'wod-11-score': 'WO',
       'wod-11-display': '8º',
       'wod-discard': '64' },
     { athletes: 'Danilo Souza',
       ranking: '326',
       index: '12',
       'wod-1': '27',
       'wod-1-score': 'WO',
       'wod-1-display': '11º',
       'wod-2': '25',
       'wod-2-score': 'WO',
       'wod-2-display': '11º',
       'wod-3': '12',
       'wod-3-score': 'WO',
       'wod-3-display': '12º',
       'wod-4': '70',
       'wod-4-score': '38.41s',
       'wod-4-display': '7º',
       'wod-5': '65',
       'wod-5-score': 'CAP + 302',
       'wod-5-display': '8º',
       'wod-6': '30',
       'wod-6-score': 'WO',
       'wod-6-display': '10º',
       'wod-7': '30',
       'wod-7-score': 'WO',
       'wod-7-display': '10º',
       'wod-8': '30',
       'wod-8-score': 'WO',
       'wod-8-display': '10º',
       'wod-9': '33',
       'wod-9-score': 'WO',
       'wod-9-display': '9º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '35',
       'wod-11-score': 'WO',
       'wod-11-display': '8º',
       'wod-discard': '64' },
     { athletes: 'Marcelo Barreto',
       ranking: '251',
       index: '13',
       'wod-1': '27',
       'wod-1-score': 'WO',
       'wod-1-display': '11º',
       'wod-2': '25',
       'wod-2-score': 'WO',
       'wod-2-display': '11º',
       'wod-3': '25',
       'wod-3-score': 'CAP + 24',
       'wod-3-display': '11º',
       'wod-4': '30',
       'wod-4-score': 'WO',
       'wod-4-display': '10º',
       'wod-5': '30',
       'wod-5-score': 'WO',
       'wod-5-display': '10º',
       'wod-6': '30',
       'wod-6-score': 'WO',
       'wod-6-display': '10º',
       'wod-7': '30',
       'wod-7-score': 'WO',
       'wod-7-display': '10º',
       'wod-8': '30',
       'wod-8-score': 'WO',
       'wod-8-display': '10º',
       'wod-9': '33',
       'wod-9-score': 'WO',
       'wod-9-display': '9º',
       'wod-10': '33',
       'wod-10-score': 'WO',
       'wod-10-display': '9º',
       'wod-11': '35',
       'wod-11-score': 'WO',
       'wod-11-display': '8º',
       'wod-discard': '77' } ],
  women:
   [ { athletes: 'Kiliana',
       ranking: '740',
       'wod-1': '70',
       'wod-1-score': '39 kgs',
       'wod-1-display': '4º',
       'wod-2': '90',
       'wod-2-score': '61 kgs',
       'wod-2-display': '2º',
       'wod-3': '100',
       'wod-3-score': '14:00',
       'wod-3-display': '1º',
       'wod-4': '70',
       'wod-4-score': '24.10s',
       'wod-4-display': '4º',
       'wod-5': '40',
       'wod-5-score': 'WO',
       'wod-5-display': '4º',
       'wod-6': '70',
       'wod-6-score': 'CAP + 36',
       'wod-6-display': '4º',
       'wod-7': '100',
       'wod-7-score': '02:19',
       'wod-7-display': '1º',
       'wod-8': '100',
       'wod-8-score': '07:58',
       'wod-8-display': '1º',
       'wod-9': '80',
       'wod-9-score': 'CAP + 50',
       'wod-9-display': '3º',
       'wod-10': '100',
       'wod-10-score': '-',
       'wod-10-display': '1º',
       'wod-11': '100',
       'wod-11-score': '00:27:35',
       'wod-11-display': '1º',
       'wod-discard': '180' },
     { athletes: 'Mariana Tavares',
       ranking: '740',
       'wod-1': '90',
       'wod-1-score': '41 kgs - tiebreak',
       'wod-1-display': '2º',
       'wod-2': '80',
       'wod-2-score': '55 kgs - tiebreak',
       'wod-2-display': '3º',
       'wod-3': '90',
       'wod-3-score': '14:38',
       'wod-3-display': '2º',
       'wod-4': '90',
       'wod-4-score': '19.55s',
       'wod-4-display': '2º',
       'wod-5': '100',
       'wod-5-score': '13:23',
       'wod-5-display': '1º',
       'wod-6': '100',
       'wod-6-score': 'CAP + 27',
       'wod-6-display': '1º',
       'wod-7': '90',
       'wod-7-score': '02:28',
       'wod-7-display': '2º',
       'wod-8': '70',
       'wod-8-score': '10:11',
       'wod-8-display': '4º',
       'wod-9': '100',
       'wod-9-score': 'CAP + 15',
       'wod-9-display': '1º',
       'wod-10': '40',
       'wod-10-score': 'WO',
       'wod-10-display': '4º',
       'wod-11': '45',
       'wod-11-score': 'WO',
       'wod-11-display': '3º',
       'wod-discard': '155' },
     { athletes: 'Juliana Jennifer',
       ranking: '670',
       'wod-1': '30',
       'wod-1-score': 'WO',
       'wod-1-display': '6º',
       'wod-2': '30',
       'wod-2-score': 'WO',
       'wod-2-display': '6º',
       'wod-3': '70',
       'wod-3-score': 'CAP + 2',
       'wod-3-display': '4º',
       'wod-4': '80',
       'wod-4-score': '23.45s',
       'wod-4-display': '3º',
       'wod-5': '90',
       'wod-5-score': '14:18',
       'wod-5-display': '2º',
       'wod-6': '100',
       'wod-6-score': 'CAP + 27',
       'wod-6-display': '1º',
       'wod-7': '60',
       'wod-7-score': 'CAP + 1',
       'wod-7-display': '5º',
       'wod-8': '90',
       'wod-8-score': '09:22',
       'wod-8-display': '2º',
       'wod-9': '50',
       'wod-9-score': 'CAP + 89',
       'wod-9-display': '6º',
       'wod-10': '90',
       'wod-10-score': '-',
       'wod-10-display': '2º',
       'wod-11': '90',
       'wod-11-score': '00:31:30',
       'wod-11-display': '2º',
       'wod-discard': '110' },
     { athletes: 'Moara',
       ranking: '655',
       'wod-1': '100',
       'wod-1-score': '49 kgs',
       'wod-1-display': '1º',
       'wod-2': '100',
       'wod-2-score': '65 kgs',
       'wod-2-display': '1º',
       'wod-3': '80',
       'wod-3-score': '17:00',
       'wod-3-display': '3º',
       'wod-4': '30',
       'wod-4-score': 'WO',
       'wod-4-display': '6º',
       'wod-5': '40',
       'wod-5-score': 'WO',
       'wod-5-display': '4º',
       'wod-6': '80',
       'wod-6-score': 'CAP + 30',
       'wod-6-display': '3º',
       'wod-7': '80',
       'wod-7-score': '02:41',
       'wod-7-display': '3º',
       'wod-8': '80',
       'wod-8-score': '09:25',
       'wod-8-display': '3º',
       'wod-9': '90',
       'wod-9-score': 'CAP + 49',
       'wod-9-display': '2º',
       'wod-10': '40',
       'wod-10-score': 'WO',
       'wod-10-display': '4º',
       'wod-11': '45',
       'wod-11-score': 'WO',
       'wod-11-display': '3º',
       'wod-discard': '110' },
     { athletes: 'Juliana',
       ranking: '550',
       'wod-1': '80',
       'wod-1-score': '41 kgs',
       'wod-1-display': '3º',
       'wod-2': '70',
       'wod-2-score': '55 kgs',
       'wod-2-display': '4º',
       'wod-3': '60',
       'wod-3-score': 'CAP + 22',
       'wod-3-display': '5º',
       'wod-4': '60',
       'wod-4-score': '49.34s',
       'wod-4-display': '5º',
       'wod-5': '40',
       'wod-5-score': 'WO',
       'wod-5-display': '4º',
       'wod-6': '30',
       'wod-6-score': 'WO',
       'wod-6-display': '6º',
       'wod-7': '70',
       'wod-7-score': '02:46',
       'wod-7-display': '4º',
       'wod-8': '60',
       'wod-8-score': 'CAP',
       'wod-8-display': '5º',
       'wod-9': '70',
       'wod-9-score': 'CAP + 84',
       'wod-9-display': '4º',
       'wod-10': '80',
       'wod-10-score': '-',
       'wod-10-display': '3º',
       'wod-11': '45',
       'wod-11-score': 'WO',
       'wod-11-display': '3º',
       'wod-discard': '115' },
     { athletes: 'Raphaela',
       ranking: '515',
       'wod-1': '60',
       'wod-1-score': '37 kgs',
       'wod-1-display': '5º',
       'wod-2': '60',
       'wod-2-score': '51 kgs',
       'wod-2-display': '5º',
       'wod-3': '50',
       'wod-3-score': 'CAP + 76',
       'wod-3-display': '6º',
       'wod-4': '100',
       'wod-4-score': '16.20s',
       'wod-4-display': '1º',
       'wod-5': '80',
       'wod-5-score': 'CAP + 64',
       'wod-5-display': '3º',
       'wod-6': '60',
       'wod-6-score': 'CAP + 110',
       'wod-6-display': '5º',
       'wod-7': '30',
       'wod-7-score': 'WO',
       'wod-7-display': '6º',
       'wod-8': '30',
       'wod-8-score': 'WO',
       'wod-8-display': '6º',
       'wod-9': '60',
       'wod-9-score': 'CAP + 85',
       'wod-9-display': '5º',
       'wod-10': '40',
       'wod-10-score': 'WO',
       'wod-10-display': '4º',
       'wod-11': '45',
       'wod-11-score': 'WO',
       'wod-11-display': '3º',
       'wod-discard': '100' } ]
};
